import React from 'react'
import styled from '@emotion/styled'


const cssClass = (hasSub) => {
  return [hasSub ? 'hasSub' : '']
    .filter(c => c !== '')
    .join(' ')

  // return 'blah'
}


const Hero = ( props ) => {
  const { 
    title, 
    subtitle,
    hasSubmenu
  } = props;
  return (
    <StyledHeroWrapper className={cssClass(hasSubmenu)}>

      <StyledHero>
        <StyledHeroContent>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </StyledHeroContent>
      </StyledHero>
    </StyledHeroWrapper>
  )}

const StyledHeroWrapper = styled.div`
  flex: 1 0 100vw;
  background-color:  ${({ theme }) => theme.mi_white};
  display: flex;
  justify-content: center;
  &:before {
    content: '';
    display: block;
    height: 0; width: 0;
    
  }
  &:before {
    padding-bottom: calc(230/375 * 100%);
  }
  &.hasSub{
    &:before{
      padding-bottom: calc(170/375 * 100%);
    }
  }
    @media(min-width: 768px){
      &:before {
        padding-bottom: calc(370/1440 * 100%);
      }
      &.hasSub{
        &:before {
          padding-bottom: calc(310/1440 * 100%);
        }
      }
    }
  
  `


const StyledHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  `
const StyledHeroContent = styled.div`
  max-width:1140px;
  h1{
    display: flex;
    justify-content: center;
    font-family: Rockwell;
    font-size: 33px;
    font-weight: normal;
    line-height: 1.27;
    color: ${({ theme }) => theme.mi_black};
    margin-bottom: 4px;
    text-align: center;
    padding: 0 24px;
  }
  h2{
    margin-top: 4px;
    font-family: SofiaW01;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.44;
    text-align: center;
    color: ${({ theme }) => theme.mi_black};
    // max-width: 70vw;
    margin: 4px auto 0 auto;
    max-width: calc(288 / 376 * 100vw);
  }
  @media(min-width: 768px){
    h1{
      font-size: 72px;
      line-height: 0.94;
      margin-bottom: 14px;
      margin-top: 0;
      max-width: calc(663 / 1440 * 100vw);
    }
    h2{
      margin-top: 14px;
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 0;
      max-width: calc(574 / 1440 * 100vw);
    }
  }
  `

const HeroHeadline = (props) => {
  const {
    title,
    subtitle
  } = props;
  return <Hero title={title} subtitle={subtitle} {...props} />
}


export default HeroHeadline
