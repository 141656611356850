import React from "react";
import { connect } from "react-redux";
import { ScrollTo } from "react-scroll-to";
import { setActivePageAnchor } from "../../state/actions";
// import StandardContent from '../Standard'
import StandardContentExtended from '../Standard'

import Section from "../Section";
import { SectionWrap } from "./styled";

const SectionsHolder = (props) => {
  const {
    active_page_anchor_id,
    setActivePageAnchor,
    items,
    refis
  } = props;

  return (
    <SectionWrap>
      {items.map((p, index) => {
        return (
          <Section
            key={`f_card_${p.id}`}
            id={`fcard_${p.id}`}
            p={p}
            index={index}
            ref={refis[p.id]}
            setActivePageAnchor={setActivePageAnchor}
            active_page_anchor_id={active_page_anchor_id}
          >
            {/* <StandardContentExtended></StandardContentExtended> */}
            {/* <pre>
              {JSON.stringify(p)}
            </pre> */}

          </Section>
        );
      })}
    </SectionWrap>
  );
};

const mapStateToProps = (state) => {
  return {
    active_page_anchor_id: state.anchor? state.anchor.active_page_anchor_id : null,
  };
};

const mapDispatchToProps = {
  setActivePageAnchor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionsHolder);


