import { React, useEffect }  from "react";
import {
  createHistory
} from "@reach/router"
import { connect } from "react-redux";
import { ScrollTo } from "react-scroll-to";
import { setActivePageAnchor } from "../../state/actions";
import { SubMenuWrap, SubMenuContainer, SubMenuItem } from "./styled";

// import { navigate } from "gatsby"
// import { Link as GatsbyLink } from "gatsby"

const Submenu = (props) => {
  const {
    active_page_anchor_id,
    setActivePageAnchor,
    is_mobile,
    items,
    refis
  } = props;



  const scrollOffset = (mobile, index) => {
    console.log('scrollOffset, isMobile: ', mobile, index)
    if(mobile) {
      return index === 0 ? 80 : 70;
    }
    return index === 0 ? 156 : 136;
  }

  const handleClick = (scrollFunc, refi, id, index) => (e) => {
    console.log('hhh handleClick, e:', e);
    console.log('hhh handleClick, window.location:', window.location);
    e.preventDefault();
    console.log('hhh handleClick, id: ', id)
    console.log('hhh handleClick, ref: ', refi)
    console.log('hhh handleClick, scrollFunc: ', scrollFunc)
    const history = createHistory(window)
    console.log('hhh handleClick, history: ', history)

    history.navigate(`${window.location.pathname}#fcard_${id}`);
    setActivePageAnchor(id);

    
    console.log('offsetTop: ', refi.current.offsetTop)
    // scrollFunc({ ref: refi, x: 20, y: 1500 })


     
    scrollFunc({ y: refi.current.offsetTop - scrollOffset(is_mobile, index), smooth: true })
  };

  // const activeParticipans = active_category.id
  //   ? participants.filter((p) => p.categoryId === active_category.id)
  //   : participants;

  const scrollOffsetOnLoad = (mobile, index) => {
    console.log('scrollOffset, isMobile: ', mobile, index)
    if(mobile) {
      return index === 0 ? 80 : -171;
    }
    return index === 0 ? 156 : 68;
  }


  useEffect(() => {
    console.log('www refis changed', JSON.stringify(Array.from(refis).map((r,index) => r.id)))
    // Run! Like go get some data from an API.
    console.log('wwww window: ', window)
    const id = window.location.hash.split('#')[1]
    // const el = document.getElementById(id)
    // const of = el.offsetTop;
    
    
    // const refiArr = [...Array.from(refis).map(r => r)]
    const refiArr = (Object.keys(refis))
    console.log('www refiArr: ', refiArr)
    if(id){
      console.log('www el id is: ', id)
      console.log('www refis: ', refis);
      const splitId = id.split('fcard_')[1]
      const refo = refis[splitId];
      const refoIndex = refiArr.indexOf(splitId)
      console.log('www refo: ', refo);
      console.log('www refo index: ', refoIndex)
      const refo2 = document.getElementById(id)
      console.log('www refo2 : ', refo2)
      const of = refo2.offsetTop;
      if(refo){
        const offi2 = refo.current.offsetTop;
        console.log('www offset top : ', offi2)
  
        const header = scrollOffsetOnLoad(is_mobile, refoIndex);
        // refo.current.scrollTop = -1000;
        // refo.current.scrollIntoView()    
        window.scrollTo({
          top: offi2 - header,
          behavior: "smooth"
        });
      }

      // refo2.scrollTop = of + 1500 //scrollOffset(is_mobile, 0);
      // if(window.location.hash){
      //   // scrollFunc({ y: of - scrollOffset(is_mobile, 0), smooth: true })
      // }
    }

  }, [...Array.from(refis).map(r => r.current.id)]);

  return (
    items && items.length ? (
      <SubMenuWrap>
        <ScrollTo>
          {({ scroll }) => (
            <SubMenuContainer>
              {items.map((p, index) => {
                return (
                  <SubMenuItem
                    className={p.id === `fcard_${active_page_anchor_id}` ? "active" : ""}
                    key={`list_item_${p.id}`}
                    to={`#fcard_${p.id}`}
                    onClick={handleClick(scroll, refis[p.id], p.id, index)}
                  >
                    {p.title}
                  </SubMenuItem>
                );
              })}
            </SubMenuContainer>
          )}
        </ScrollTo>
      </SubMenuWrap>
    ) : null )
};

const mapStateToProps = (state) => {
  return {
    // participants: state.all.participants,
    // active_category: state.all.active_category,
    active_page_anchor_id: state.active_page_anchor_id,
    is_mobile: state.responsive.isPhone,
  };
};

const mapDispatchToProps = {
  setActivePageAnchor
};

export default connect(mapStateToProps, mapDispatchToProps)(Submenu);
