import React, { useState } from "react";
import styled from '@emotion/styled'
import FluidImage from "../FluidImage"
import StandardContent from '../StandardExtended'
import { Card } from "./styled";

const StyleFluidImageWrapper = styled.div`   
    flex: 1 0 100%;
    width: 100%;
    margin-top: 72px;
    margin-bottom: 42px;
    @media(min-width: 768px){
      width: 768px;
      margin-right: auto;
      margin-left: auto;
    }
    @media(min-width: 1140px){
      width: 945px;
      margin-bottom: 76px;
      margin-top: 96px;

    }
  `
const StyledH2 = styled.h2`
  font-family: Rockwell;
  font-weight: bold;
  max-width: 750px;

  margin: 48px 0px 24px 0px;
  font-size: 20px;
  line-height: 1.6;

  &.first{
    margin-top: 0;
  }

  @media(min-width: 768px){
    // margin: 0 97px 54px 98px;
    font-size: 36px;
    line-height: 1.28;
    margin-top: 120px;
    margin-bottom: 54px;
    margin: 120px auto 54px auto;
  
    &.first{
      margin-top: 0;
    }
  
  }

  
`

const Section = React.forwardRef((props, ref) => {
  const { active_page_anchor_id, id, p, setActivePageAnchor, index } = props;
  const [isHovered, setHover] = useState(false);
  const cardIsActive = active_page_anchor_id === p.id || isHovered;
  // const cardIsActive = active_page_anchor_id 
  // const cardIsActive = isHovered;

  // const handleClick = (participant) => (e) => {
  //   setActiveParticipant(participant.id);
  // };

  const cssClass = (cIsActive, i) => {
    return [cIsActive ? 'active' : '', i === 0 ? 'first' : '', p.contentTitle && p.contentTitle !== '' ? '' : 'noh2']
      .filter(c => c !== '')
      .join(' ')

    // return 'blah'
  }

  const h2Class = (i) => {

    return [i === 0 ? 'first' : '']
      .filter(c => c !== '')
      .join(' ')

    // return 'blah'

  }

  return (
    <Card
      ref={ref}
      className={cssClass(cardIsActive, index)}
      key={`card_item_${p.id}`}
      id={id}
      // onClick={handleClick(p)}
      // onMouseEnter={() => {
      //   setHover(true);
      // }}
      // onMouseLeave={() => {
      //   setHover(false);
      // }}
    >
      {/* <div>
        cardIsActive: 
        {cardIsActive}
        <br />
        index:
        {index}
        <br />
        cssClass 
        {cssClass(cardIsActive, index)}
      </div> */}
      {props.children}
      {/* <div>{JSON.stringify(p.miStandardContentV4)}</div> */}
      {p.contentTitle && p.contentTitle !== '' ? (<StyledH2 className={h2Class(index)}>{p.contentTitle}</StyledH2>) : null}
      {
        p.miStandardContentV4.map(( node, index ) => {
          switch(node.fieldGroupName){
          case 'page_MiStandardV4_miStandardContentrepeaterV4_MiStandardContentV4_MiStandardContentText':
            return <StandardContent key={`scontent_${index}`} content={node.miStandardContentTextWysiwygV4} />
          case 'page_MiStandardV4_miStandardContentrepeaterV4_MiStandardContentV4_MiStandardContentImage':
            return (
              <StyleFluidImageWrapper key={node.miStandardContentImageUrlV4.sourceUrl}>
                <FluidImage cap={node.miStandardContentImageCaptionV4} image={node.miStandardContentImageUrlV4} style={{marginBottom: '96px', marginTop:'96px'}} />
              </StyleFluidImageWrapper>
            )
          default:
            return null
          }
        })
      }
    </Card>
  );
});

export { Section as default };
