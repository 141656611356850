import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import FluidImage from "../components/FluidImage"
import HeroHeadline from '../components/NewHeader/HeroHeadline';
import SectionsHolder from '../components/SectionsHolder';
import Seo from '../components/Seo'
import Submenu from "../components/Submenu";

function slugify(t, separator) {
  console.log('slugify t: ', t, ', separator: ', separator)
  let text = t.toString().toLowerCase().trim()

  const sets = [
    { to: "a", from: "[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]" },
    { to: "ae", from: "[Ä]" },
    { to: "c", from: "[ÇĆĈČ]" },
    { to: "d", from: "[ÐĎĐÞ]" },
    { to: "e", from: "[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
    { to: "g", from: "[ĜĞĢǴ]" },
    { to: "h", from: "[ĤḦ]" },
    { to: "i", from: "[ÌÍÎÏĨĪĮİỈỊ]" },
    { to: "j", from: "[Ĵ]" },
    { to: "ij", from: "[Ĳ]" },
    { to: "k", from: "[Ķ]" },
    { to: "l", from: "[ĹĻĽŁ]" },
    { to: "m", from: "[Ḿ]" },
    { to: "n", from: "[ÑŃŅŇ]" },
    { to: "o", from: "[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
    { to: "oe", from: "[ŒÖ]" },
    { to: "p", from: "[ṕ]" },
    { to: "r", from: "[ŔŖŘ]" },
    { to: "s", from: "[ŚŜŞŠ]" },
    { to: "ss", from: "[ß]" },
    { to: "t", from: "[ŢŤ]" },
    { to: "u", from: "[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
    { to: "ue", from: "[Ü]" },
    { to: "w", from: "[ẂŴẀẄ]" },
    { to: "x", from: "[ẍ]" },
    { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
    { to: "z", from: "[ŹŻŽ]" },
    { to: "-", from: "[·/_,:;']" },
  ]

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, "gi"), set.to)
  })

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text

  if (typeof separator !== "undefined" && separator !== "-") {
    text = text.replace(/-/g, separator)
  }

  return text
}


const StyledStandardContentWrapper = styled.div`   
  background-color:  ${({ theme }) => theme.mi_background_real};
  padding: 48px 24px 72px 24px;
  max-width:1140px;
  overflow: scroll;
  margin: 0 auto;
  @media(min-width: 1190px){
    padding: 48px 0px 72px 0px;
  }
  `

const StyleFluidImageWrapperHero = styled.div`   
  width: 100%;
  margin-top: 0
  margin-bottom: 48px;
  @media(min-width: 1140px){
    margin-bottom: 121px;
    margin-top: 0
  }
`

const PageTemplate = (props) => {
  const {
    data: {
      wpPage: { cleanTitle, mi_standard_v4, seo, slug },
    },
    active_page_anchor_id,
  } = props

  const { metaDesc, metaKeywords, metaRobotsNofollow, metaRobotsNoindex, title, opengraphUrl} = seo;

  const {miStandardContentrepeaterV4} = mi_standard_v4

  const withIds = miStandardContentrepeaterV4?
    miStandardContentrepeaterV4
      .map(m => {
        return {
          ...m,
          hasSubMenu: m.miStandardRepeaterItemHasSubmenuV4,
          submMenuTitle: m.miStandardRepeaterItemSubmenuTitleV4,
          contentTitle: m.miStandardRepeaterItemContentTitleV4,
          id: m.miStandardRepeaterItemSubmenuTitleV4? slugify(m.miStandardRepeaterItemSubmenuTitleV4) : ''
        }
      }) : [];

  const submenu = miStandardContentrepeaterV4?
    miStandardContentrepeaterV4
      .filter(m => m. miStandardRepeaterItemHasSubmenuV4 === true)
      .map(m => {
        return {
          title: m.miStandardRepeaterItemSubmenuTitleV4,
          id: m.miStandardRepeaterItemSubmenuTitleV4? slugify(m.miStandardRepeaterItemSubmenuTitleV4) : ''
        }
      }) : []
  

  const hasSubmenuItems = submenu && submenu.length;

  const refis = submenu? submenu.reduce((acc, item) => {
    acc[item.id] = React.createRef();
    return acc;
  }, {}) : null;

  return (
    <>
      <Seo title={cleanTitle} description={metaDesc} keywords={metaKeywords} noFollow={metaRobotsNofollow} noIndex={metaRobotsNoindex} seoTitle={title} url={opengraphUrl} slug={slug} />

      <HeroHeadline key={cleanTitle} title={cleanTitle} subtitle={mi_standard_v4.miStandardSubheadlineV4} hasSubmenu={hasSubmenuItems} />
      <Submenu id="mi-sub-header" items={submenu} refis={refis} />
      {mi_standard_v4.miStandardHeroImageV4 ? (
        <StyleFluidImageWrapperHero>
          <FluidImage cap={mi_standard_v4.miStandardHeroImageCaptionV4} key={`hero${mi_standard_v4.miStandardHeroImageV4.sourceUrl}`} image={mi_standard_v4.miStandardHeroImageV4} style={{marginBottom: '121px'}} /> 
        </StyleFluidImageWrapperHero>
      ) : null }

      <StyledStandardContentWrapper>
        <SectionsHolder items={withIds} refis={refis} />
      </StyledStandardContentWrapper>

    </>
  )
}

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    wpPage(id: {eq: $id}) {
      ...PageContent
      seo {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        opengraphUrl
      }
      mi_standard_v4 {
        miStandardSubheadlineV4
        miStandardContentrepeaterV4 {
          miStandardRepeaterItemHasSubmenuV4
          miStandardContentV4 {
            ... on WpPage_MiStandardV4_miStandardContentrepeaterV4_MiStandardContentV4_MiStandardContentText  {
              fieldGroupName
              miStandardContentTextWysiwygV4
            }
            ... on WpPage_MiStandardV4_miStandardContentrepeaterV4_MiStandardContentV4_MiStandardContentImage {
              fieldGroupName
                miStandardContentImageCaptionV4
              miStandardContentImageUrlV4 {
                sourceUrl
                altText
                caption
                imageFile {
                  childImageSharp {
                      fluid(
                        maxHeight: 1400, 
                        quality: 80, 
                        cropFocus: CENTER,
                        traceSVG: { background: "#fff", color: "#f2ebe7" }
                      ) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                  }
                }
              }
            }
          }
          miStandardRepeaterItemSubmenuTitleV4
          miStandardRepeaterItemContentTitleV4
        }

        miStandardHeroImageCaptionV4,
        miStandardHeroImageV4 {
          sourceUrl
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(
                maxHeight: 1000, 
                quality: 80, 
                cropFocus: CENTER,
                traceSVG: { background: "#fff", color: "#f2ebe7" }
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
