import React from 'react'
import styled from '@emotion/styled'

const StyledSectioncontent = styled.div`

h3{
  font-family: SofiaW01;
  font-weight: bold;
  max-width: 750px;
  margin: 44px 0 25px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.6px;
  @media(min-width: 768px){
    font-size: 20px;
    margin: 68px auto 36px auto;
    line-height: 1.7;
    letter-spacing: 0.6px;
  }
}

`

const Content = ( props ) => {
  const { 
    content,
    id
  } = props;
  return (

    <StyledSectioncontent
      key={id}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )}

const StandardContent = (props) => {
  const {
    content,
    id
  } = props;
  return <Content id={id} content={content} {...props} />
}


export default StandardContent
