import styled from "@emotion/styled";
import { Link as GatsbyLink } from "gatsby"

const SubMenuWrap = styled.div`
  transform: translate3d(0,0,0);
  position: sticky;
  top: 80px;
  width: 100vw;
  z-index: 3;
  background: white;
  overflow-x: scroll;

  @media(min-width: 768px){
    top: 95px;
  }
`

const SubMenuContainer = styled.div`
  max-width: 1140px;
  display: flex;
  padding: 16px 10px 16px 24px;
  box-sizing: border-box;
  justify-content: flex-start;
  float: left;
  @media(min-width: 768px){
    justify-content: center;
    margin: 0 auto;
    padding: 16px 24px 16px 24px;
    float: none;
  }
`

const SubMenuItem = styled(GatsbyLink)`
  background-color: #f6efeb;
  color: #ea5223;
  font-family: SofiaW01;
  line-height: 1.33;

  
  margin: 0 14px 0 0;
  padding: 3px 13.5px 5px 14.5px;
  border-radius: 14.5px;
  font-size: 15.3px;

  white-space: nowrap;
  text-decoration: none;

  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;
  transform: scale3d(1,1,1);
  &:hover{
    transform: scale3d(1.02,1.02,1.02);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.02);
  }

  @media(min-width: 768px){
    margin: 0 16px 0 0;
    padding: 3px 16px 3px 17px;
    border-radius: 17px;
    font-size: 18px;
  }
`



export {
  SubMenuWrap,
  SubMenuContainer,
  SubMenuItem
};
